import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import { isForDev, API_URL, USE_DEMO_DATA } from './config';
import axios from 'axios';
import { base64toBlob, readBlob } from './helpers';
import { LineWave } from 'react-loader-spinner';

const PLACEHOLDER_SUBMIT = ['','','',''];
const PLACEHOLDER_STYLES = {
  "ph0":[{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false}],
  "ph1":[{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false}],
  "ph2":[{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false}],
  "ph3":[{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false}],
};

const DEMO_DATA = {
  "id": 12,
  "assigned_reviewer": null,
  "email": "rachel@ulo.world",
  "firstname": "Rachel",
  "lastname": "Chang",
  "cat_name": "MISO",
  "all_approved": null,
  "pulled": true,
  "generated": false,
  "cat_photos": [
    {"id": 23, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_0267.jpg"},
    {"id": 24, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_0644.jpg"},
    {"id": 25, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_1032.jpg"},
    {"id": 26, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_1168.jpg"},
    {"id": 27, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_1267.jpg"},
    {"id": 28, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_1733.jpg"},
    {"id": 29, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_2028.jpg"},
    {"id": 30, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_7991.jpg"},
    {"id": 31, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_8094.jpg"},
    {"id": 32, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_8279.jpg"},
    {"id": 33, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_photos/IMG_8420.jpg"},
  ],
  "art_styles": [
    {"id": 45, "style_name": "Animation", "approved": null, "image_url": ""},
    {"id": 46, "style_name": "Anime", "approved": null, "image_url": ""},
    {"id": 47, "style_name": "Watercolor", "approved": null, "image_url": ""},
    {"id": 48, "style_name": "Psychedelic", "approved": null, "image_url": ""}
  ],
  "generated_photos": [
    {"id": 23, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_anime_4.png", "style": "Anime"},
    {"id": 43, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_anime_5.png", "style": "Anime"},
    {"id": 25, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_anime_6.png", "style": "Anime"},
    {"id": 67, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_anime_7.png", "style": "Anime"},
    {"id": 23, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_watercolor_1.png", "style": "Watercolor"},
    {"id": 43, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_watercolor_2.png", "style": "Watercolor"},
    {"id": 25, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_watercolor_3.png", "style": "Watercolor"},
    {"id": 67, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_watercolor_4.png", "style": "Watercolor"},
    {"id": 23, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_pixar_1.png", "style": "Animation"},
    {"id": 43, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_pixar_2.png", "style": "Animation"},
    {"id": 25, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_pixar_3.png", "style": "Animation"},
    {"id": 67, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_pixar_4.png", "style": "Animation"},
    {"id": 23, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_psyc_1.png", "style": "Psychedelic"},
    {"id": 43, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_psyc_2.png", "style": "Psychedelic"},
    {"id": 25, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_psyc_3.png", "style": "Psychedelic"},
    {"id": 67, "cat_photo_url": "https://jennai-storage.s3.amazonaws.com/mars_outputs/u_psyc_4.png", "style": "Psychedelic"},
  ]
};

const Refresh = (props) => {
  return (
    <button className="ulo-refresh ulo-btn ulo-btn-dark" onClick={() => props.onClick()}>
      <div className='ulo-referesh-img'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" fill="#FFFFFF" />
        </svg>
      </div>
    </button>
  )
};

const ConfirmRejection = (props) => {
  return ReactDOM.createPortal(
    <div className="ulo-confirm-rejection">
      <div className="ulo-confirm-rejection-content">
        <p>Are you sure you want to reject all generated images in {props.skippedStyles.length > 1 ? 'these styles' : 'this style'}?</p>
        <div className="ulo-skipped-styles">
          {props.skippedStyles.map((style, i) => (
            <div key={`skipped-style-${i}`} className="ulo-skipped-style">{style}{i < props.skippedStyles.length - 1 && props.skippedStyles.length > 1 ? ',' : ''}</div>
          ))}
        </div>
        <div className="ulo-button-wrapper">
          <button className="ulo-btn ulo-btn-light" onClick={() => props.onCancel()}>Cancel</button>
          <button className="ulo-btn ulo-btn-dark" onClick={() => props.onConfirm()}>Confirm</button>
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
};

function HumanApproval(props) {
  const [submittedPhotos, setSubmittedPhotos] = useState([]);
  const [currentUserSubmissionID, setCurrentUserSubmissionID] = useState(-1);
  const [queueSize, setQueueSize] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [skippedStyles, setSkippedStyles] = useState([]);
  const [submitStyles, setSubmitStyles] = useState({});
  const [loading, setLoading] = useState(false);
  const [styles, setStyles] = useState({});
  const [styleSkeleton, setStyleSkeleton] = useState(true);

  const queueUpdate = async () => {
    setLoading(true);
    if (USE_DEMO_DATA) {
      processData(DEMO_DATA);
    } else {
      axios.get(`${API_URL}/api/review/`, {params:{username: props.username}})
      .then(res => {
        processData(res.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
    }
  };

  const processData = (data) => {
    setQueueSize(USE_DEMO_DATA ? 1 : data.queue);
    if ((USE_DEMO_DATA && data.cat_photos.length === 0) || (!USE_DEMO_DATA && data.queue === 0)) {
      setSubmittedPhotos(PLACEHOLDER_SUBMIT);
      setStyles(PLACEHOLDER_STYLES);
      setStyleSkeleton(true);
      setCurrentUserSubmissionID(-1);
    } else {
      const submitted_photos = data.cat_photos.slice(0, 4).map(photo => photo.cat_photo_url);
      setSubmittedPhotos(submitted_photos);

      const art_styles = {};
      data.generated_photos.forEach(photo => {
        if (!(photo.style in art_styles)) {
          art_styles[photo.style] = [];
        }
        art_styles[photo.style].push({photo: photo.cat_photo_url, photoUrl: photo.cat_photo_url, selected: false});
      });
      setStyles(art_styles);
      setStyleSkeleton(false);

      setCurrentUserSubmissionID(data.id);
    }
  };

  const handleSubmit = () => {
    const allSubmitStyles = {}
    const allSkippedStyles = []
    for (const [key, value] of Object.entries(styles)) {
      allSubmitStyles[key] = []
      let styleSkipped = true;
      for (const photo of value) {
        if (photo.selected) {
          styleSkipped = false;
        }
        allSubmitStyles[key].push({photoUrl: photo.photoUrl, selected: photo.selected})
      }
      if (styleSkipped) {
        allSkippedStyles.push(key);
      }
    }
    if (allSkippedStyles.length > 0) {
      setSkippedStyles(allSkippedStyles);
      setSubmitStyles(allSubmitStyles);
      setShowModal(true);
      return;
    }

    setLoading(true);

    const payload = {
      user_submission_id: currentUserSubmissionID,
      styles: allSubmitStyles,
    };

    console.log('payload', payload);
    
    if (USE_DEMO_DATA) {
      // Simulate API call for demo
      setTimeout(() => {
        setLoading(false);
        queueUpdate();
      }, 1000);
    } else {
      axios.patch(`${API_URL}/api/review/`, payload)
      .then(res => {
        console.log(res);
        queueUpdate();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
    }
  };

  const toggleSelect = (styleIndex, photoIndex) => {
    setStyles(m => ({
      ...m,
      [styleIndex]: m[styleIndex].map((photo, pIndex) => ({
        ...photo,
        selected: pIndex === photoIndex ? !photo.selected : false
      }))
    }));
  };

  const handleModal = (option) => {
    setShowModal(false);
    setSkippedStyles([]);
    if (option === 'confirm') {
      setLoading(true);

      const payload = {
        user_submission_id: currentUserSubmissionID,
        styles: submitStyles,
      };
  
      console.log('payload', payload);
      
      if (USE_DEMO_DATA) {
        // Simulate API call for demo
        setTimeout(() => {
          setLoading(false);
          queueUpdate();
          setSubmitStyles({});
        }, 1000);
      } else {
        axios.patch(`${API_URL}/api/review/`, payload)
        .then(res => {
          console.log(res);
          queueUpdate();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setSubmitStyles({});
        });
      }
    }
  };

  useEffect(() => {
    queueUpdate();
  }, []);

  return (
    <>
      { isForDev &&
        <div className='ulo-dev-buttons'>
          *for dev*
          <button onClick={() => props.changePage('login')}>&lt;</button>
          <button onClick={() => props.changePage('none')}>&gt;</button>
        </div>
      }
      <div className="ulo-human-approval ulo-background-gradient-reverse">
        <header className="ulo-header">
          <div className="ulo-header-title">
            <h1>Choose a photo for each style {isForDev ? currentUserSubmissionID : ""}</h1>
          </div>
          <div className="ulo-header-queue">
            <p><b>{queueSize} Submissions Left</b><br />in Queue</p>
          </div>
        </header>
        <main className="ulo-desktop-wrapper">
          <aside className="ulo-submitted-photos">
            <p className='ulo-btn ulo-human-approval-btn'>Submitted photos</p>
            <div className='ulo-submitted-photos-wrapper'>
              {submittedPhotos.map((submittedPhoto,i) => (
                <div key={`submitted_photos_${i}`} className='ulo-photo-container'>
                  <div className='ulo-photo-window'>
                    {
                      submittedPhoto === '' ?
                        <></>
                      :
                        <img src={submittedPhoto} alt="submitted img" />
                    }
                  </div>
                </div>
              ))}
            </div>
          </aside>
          <div className='ulo-style-wrapper'>
            {Object.entries(styles).map((style) => (
              <div key={style[0]} className="ulo-style-section">
                {
                  styleSkeleton ?
                    <></>
                  :
                    <p className='ulo-btn'>{style[0]} <br /> Style</p>
                }
                <div className="ulo-image-grid">
                  {style[1].map((photo, photoIndex) => (
                    <div
                      key={`generated-image-${photoIndex}`}
                      className={`ulo-photo-container ${photo.selected ? 'ulo-selected' : ''}`}
                      onClick={() => toggleSelect(style[0], photoIndex)}
                    >
                      <div className='ulo-photo-window'>
                        {
                          styleSkeleton ?
                            <></>
                          :
                            <>
                              <img src={photo.photo} alt={`${style[0]} style`} />
                              {photo.selected && (
                                <div className="ulo-checkmark-container">
                                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="18" cy="18" r="17" fill="#FFEF86" fillOpacity="0.2" stroke="white" strokeWidth="2" />
                                    <path d="M11 20L14.5 23.5L26 12" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                  </svg>
                                </div>
                              )}
                            </>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </main>
        <footer className="ulo-button-wrapper">
          <button className="ulo-btn ulo-btn-dark" onClick={() => handleSubmit()}>
            <div>Submit →</div>
            {loading && <LineWave />}
          </button>
          {isForDev ? <Refresh onClick={queueUpdate} /> : <></>}
        </footer>
      </div>
      {
        showModal &&
        <ConfirmRejection
          onCancel={() => handleModal('cancel')}
          onConfirm={() => handleModal('confirm')}
          skippedStyles={skippedStyles}
          submitStyles={submitStyles}
          setLoading={setLoading}
        />
      }
    </>
  );
}

export default HumanApproval;