import React, { useState } from 'react';
import './App.css';
import Footer from './Footer.js';
import HumanApproval from './HumanApproval.js';
import Login from './Login.js';

function App() {
    const [currentPage, setCurrentPage] = useState('login');
    const [passProps, setPassProps] = useState({});

    const validPages = ['login', 'humanApproval'];

    const changePage = (page, props) => {
      if (validPages.includes(page)) {
        setCurrentPage(page);
      }
      if (props) {
        setPassProps(props);
      }
    };

  return (
    <div className="ulo-App">
      {currentPage === 'login' && <Login {...passProps} changePage={changePage} />}
      {currentPage === 'humanApproval' && <HumanApproval {...passProps} changePage={changePage} />}
      {currentPage !== 'humanApproval' && currentPage !== 'login' && <Footer />}
    </div>
  );
}

export default App;
